import React from "react"
import Layout from "../components/layout"
import queryString from "query-string"

class ThankYou extends React.Component {

  render() {

    return (
      <Layout title="Thank You">
        <h2>Thank you for your order!</h2>
        <div className="clear-block">
          <div id="node-21" className="node">
            <div className="content clear-block">
              Your order <b>{this.orderId()}</b> has been received.
            </div>

            <div className="clear-block">
              <div className="meta">
              </div>

            </div>

          </div>
        </div>
      </Layout>
    )
  }

  orderId() {
    const parsed = queryString.parse(this.props.location.search)
    return parsed.order
  }
}

export default ThankYou
